/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { Chip, Stack, useTheme } from '@mui/material';
import { useTopBarStyle } from './style';

// Icons
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { authState } from '../../store/auth';
import { userState } from '../../store/user';
import { useTranslation } from 'react-i18next';
import { CompanyEmployeesRoles, permissions } from '../../services/roles';
import { matchPath, useHistory, useLocation } from 'react-router';
import { companyDataState } from '../../store/company-data';
import workIcon from '../../assets/work.svg';
import { Notifications } from './Notifications';
import { useLogoutMutation } from '../../queries/auth/logout';
import { useEffect } from 'react';
import { notificationsState } from '../../store/notifications';
import { LoadingState } from '../../store/global-loading';
import { livePreviewState } from '../../store/live-preview';

const settings = ['Logout'];

const TopBar: React.FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const classes = useTopBarStyle();

  const setAuth = useSetRecoilState(authState);
  const [user, setUser] = useRecoilState(userState);
  const [livePreview, setLivePreview] = useRecoilState(livePreviewState);
  const {
    company: { logo, serviceStatus, company_feature },
  } = useRecoilValue(companyDataState);
  const isLivePreview = useRecoilValue(livePreviewState);
  const [notifications, setNotifications] = useRecoilState(notificationsState);

  const setIsLoading = useSetRecoilState(LoadingState);

  const { mutate: logout, status, error, isLoading } = useLogoutMutation();

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null,
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null,
  );

  useEffect(() => {
    if (status === 'success') {
      onLogout();
    } else if (status === 'error') {
      onLogout();
    }
  }, [status]);

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const onLogout = () => {
    setAuth({
      token: null,
      refreshToken: null,
    });
    setUser({});
    setLivePreview(null);
    sessionStorage.clear();
    localStorage.clear();
  };

  const onSettingOption = (option: string) => {
    handleCloseUserMenu();
    option === 'Logout' && logout();
  };

  const isExactMatch = (path: string) =>
    path
      ? !!matchPath(location.pathname, {
          path,
          exact: false,
        })
      : false;

  const pages = [
    {
      role: permissions.management,
      name: 'Internet',
      path: '/management',
    },
    {
      role: permissions.applicationfiltration,
      name: 'Firewall',
      path: '/firewall',
    },
    {
      role: permissions.doorAccess,
      name: 'Door access',
      path: '/door-access',
      isNew: false,
    },
    {
      role: permissions.analytics,
      name: 'Analytics',
      path: '/analytics',
    },

    {
      role: permissions.marketing,
      name: 'Marketing',
      path: '/marketing',
    },
    {
      role: permissions.marketing,
      name: 'Loyalty',
      path: '/loyalty',
    },
    {
      role: permissions.settings,
      name: 'Settings',
      path: '/settings',
    },
  ];

  return (
    <AppBar position='sticky' sx={{ zIndex: theme.zIndex.modal }}>
      <Toolbar
        disableGutters
        sx={{
          minHeight: '58px !important',
          paddingX: '38px',
          justifyContent: 'space-between',
        }}
      >

        {serviceStatus === 'enabled' && (
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'flex', md: 'none' },
            }}
          >

            <IconButton
              size='large'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleOpenNavMenu}
              color='inherit'
            >
              <MenuIcon />
            </IconButton>

            <Menu
              id='menu-appbar'
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map(({ name, path, role }) =>
                role.includes(user?.role) ? (
                  <MenuItem
                    key={path}
                    onClick={() => {
                      history.push(path);
                      handleCloseNavMenu();
                    }}
                  >
                    <Typography textAlign='center'>{name}</Typography>
                  </MenuItem>
                ) : (
                  <></>
                ),
              )}
            </Menu>
          </Box>
        )}

        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>

          <IconButton
            sx={{ p: 0, mr: { md: '16px' } }}
            onClick={() => (isLivePreview != 'true' ? history.push('/') : {})}
          >
            {isLivePreview != 'true' ? (
              <Avatar
                alt='winfi'
                src={require('../../assets/whitelogo.webp')}
                variant='square'
                sx={{ width: 24, height: 24 }}
              />
            ) : (
              <Chip
                sx={{ fontWeight: 500 }}
                label='Live preview mode'
                color='error'
              />
            )}
          </IconButton>

          <IconButton sx={{color: "white"}}>
            |
          </IconButton>

          {serviceStatus === 'enabled' &&
            pages.map(({ name, path, role, isNew }) =>
              role.includes(user?.role) ? (
                <Stack direction={'row'} alignItems={'center'} key={path}>
                  <Button
                    key={path}
                    onClick={() => history.push(path)}
                    className={
                      isExactMatch(path) ? classes.active : classes.normal
                    }
                    sx={{
                      color: 'white',
                      fontWeight: isExactMatch(path)
                        ? 'fontWeightMedium'
                        : 'fontWeightRegular',
                      display: 'flex',
                      marginX: '4px',
                      marginY: '8px',
                      paddingX: "12px",
                      minHeight: "36px",
                      "&:hover": {
                        backgroundColor: isExactMatch(path) ? "#3C3C43" : "transparent",
                      }
                    }}
                  >
                    {name}
                    {isNew ? (
                      <Box
                        sx={{
                          bgcolor: 'success.main',
                          padding: '1px 8px',
                          borderRadius: '4px',
                          ml: '8px',
                        }}
                      >
                        <Typography variant={'body2'} color='#fff'>
                          New
                        </Typography>
                      </Box>
                    ) : null}
                  </Button>
                </Stack>
              ) : (
                <></>
              ),
            )}
        </Box>

        <Box sx={{ flexGrow: 0, display: 'flex' }}>
          <Notifications />

          <Avatar alt='winfi' src={logo || workIcon} sx={{ ml: 2 }} />

          <Button
            endIcon={<ArrowDropDownIcon style={{ color: '#fff' }} />}
            sx={{ textAlign: 'left', alignItems: 'flex-start', pl: 1 }}
            onClick={handleOpenUserMenu}
          >
            <Box>
              <Typography
                display='block'
                style={{ color: '#fff', fontWeight: 300, fontSize: '14px' }}
              >
                {user.firstName} {user.lastName}
              </Typography>

              <Typography
                style={{
                  color: 'rgba(255, 255, 255, 0.7)',
                  fontWeight: 300,
                  fontSize: '14px',
                }}
              >
                {t(`teamMembers.${user.role as CompanyEmployeesRoles}`)}
              </Typography>
            </Box>
          </Button>

          <Menu
            sx={{ mt: '45px' }}
            id='menu-appbar'
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting) => (
              <MenuItem key={setting} onClick={() => onSettingOption(setting)}>
                <Typography textAlign='center'>{setting}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
