import { makeStyles } from '@mui/styles';
export const useTopBarStyle = makeStyles(() => ({
  normal: {
    fontWeight: 500,
  },
  active: {
    position: 'relative',
    color: '#fff',
    // background: "#283448",
    fontWeight: 'bold',
    background: "#3C3C43",
    borderRadius: "20px"
    // '&::after': {
    //   content: '""',
    //   width: '100%',
    //   height: '4px',
    //   backgroundColor: '#0076ff',
    //   left: "0%",
    //   position: 'absolute',
    //   bottom: '-8px',
    //   borderRadius: '5px 5px 0 0',
    // },
  },
}));
